import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'midautumn',
    },
    meta: {
      title: '查询车主成交价',
    },
  },
  {
    name: 'car',
    path: '/car',
    component: () => import('./view/car'),
    meta: {
      title: '查询车主成交价',
    },
  },
  {
    name: 'midautumn',
    path: '/midautumn',
    component: () => import('./view/midautumn'),
    meta: {
      title: '全民购车中秋特惠',
    },
  },
  {
    name: 'page1',
    path: '/page1',
    component: () => import('./view/page1'),
    meta: {
      title: '全民购车',
    },
  },
  {
    name: 'page2',
    path: '/page2',
    component: () => import('./view/page2'),
    meta: {
      title: '全民购车',
    },
  },
  {
    name: 'page3',
    path: '/page3',
    component: () => import('./view/page3'),
    meta: {
      title: '全民购车',
    },
  },
  {
    name: 'info',
    path: '/info',
    component: () => import('./view/info'),
    meta: {
      title: '个人信息保护声明',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
